import { useEffect, useState } from 'react';
import useAxios from './useAxios';

const useAccreditations = ({ options, ...axiosConfig } = {}) => {
  const [{ data, error, loading }, getAccreditations] = useAxios({ url: '/accreditations', ...axiosConfig }, options);

  const [accreditations, setAccreditations] = useState([]);

  const [total, setTotal] = useState(0);

  const [size, setSize] = useState(0);

  const [numberOfPages, setNumberOfPages] = useState(0);

  useEffect(() => {
    if (data) {
      setAccreditations(data?.data);
      setTotal(data?.meta?.total);
      setSize(data?.meta?.per_page);
      setNumberOfPages(data.meta?.last_page);
    }

  }, [data, loading, error]);

  return [{ accreditations, total, numberOfPages, size, error, loading }, getAccreditations];
};

export default useAccreditations;
